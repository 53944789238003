export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',
  LOAD_MORE_BUTTON_CLASS: 'button button--secondary',

  SORT_CONTAINER_CLASS: 'actionBar',

  VEHICLE_WIDGET_HOME_CLASS: 'container',

  GO_TEXT: '<span>GO</span>',
  CLEAR_TEXT: '<span>Clear</span>',
  CHANGE_VEHICLE: '<span>Change</span>',
  DISCARD_VEHICLE: '<span>Clear</span>',
  ADD_VEHICLE: '<span>Add a vehicle</span>',

  START_OVER: '<span>Start over<span>',
  PRICE_FACET_GO_TEXT: '<span>GO</span>',
  FACET_DIALOG_CLEAR: '<span>Clear</span>',
  FACET_DIALOG_DONE: '<span>Done</span>',
  FILTERS_TEXT: '<span>Filters</span>',

  LOAD_MORE_TEXT:
    '<svg class="icon" title="icon refresh"><use xlinkHref="#icon-refresh"></use></svg><span class="text">Show More</span>',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',
};
