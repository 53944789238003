//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-928:_608,_9750,_5824,_1544,_9544,_1322,_5320,_3664;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-928')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-928', "_608,_9750,_5824,_1544,_9544,_1322,_5320,_3664");
        }
      }catch (ex) {
        console.error(ex);
      }